<template>
    <div>
        <page-title  :heading="$t('reports.saleReport') " :subheading="$t('reports.saleReport')" :icon=icon></page-title>
        <div class="app-main__inner">
        <SaleReport></SaleReport>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import SaleReport from "../../components/reports/SaleReport";

    export default {
        components: {
            PageTitle,
            SaleReport
        },
        data: () => ({
            icon: 'pe-7s-news-paper icon-gradient bg-tempting-azure',
        })
    }
</script>